//项目中我们大多数时候会将对应的接口请求封装成api来调用
import service from "../service.js";

//登录接口
export const login = params => {
  return service({
    method: 'POST',
    url: '/admin/login',
    params
  })
}

// 获取公钥
export const getPublicKey = () => {
  return service({
    method: 'GET',
    url: '/admin/getPublicKey'

  })
}
// 登出
export const logout = () => {
  return service({
    method: 'GET',
    url: '/admin/logout'
  })
}

export const keyData = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCIxMiKApCCoAA5JOrVdMUQSDfjgINDsZ4GNfnzHQRZm2iaR/jOdgcWRBzDKV2O4tHkTV3VLt2pJpVoZuTg7uqdkBB+lCWdtsl0DQQYBDuNzjphPZZW57vSvrwlcZbYFW5I1LWf3s/z3Mw3qoMxr4E9bGVT35mrq1QIEnRVdrZTMwIDAQAB'



